import { useLogger } from 'amazon-chime-sdk-component-library-react';
import { LogLevel } from 'amazon-chime-sdk-js';
import { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  errorBoundaryMessages,
  HunchVueDemoMeetingTitle,
  isDemoRegex,
} from './constants/messages';
import { useAppDispatch } from './hooks/useAppDispatch';
import * as Sentry from '@sentry/react';

import { useAppSelector } from './hooks/useAppSelector';
import LoadingScreen from './screens/LoadingScreen';
import { setInternal, setIsDemo } from './store/Auth/AuthSlice';
import { setMeetingName } from './store/Meeting/MeetingSlice';
import DemoThankYouPage from './pages/DemoThankYouPage';
import ErrorFallback from './components/ErrorFallback';
import { SentryRoutes } from '.';

const AuthPage = lazy(() => import('./pages/AuthPage'));
const HunchVueSharePage = lazy(() => import('./pages/ShareHunchVuePage'));
// const HomeV2Page = lazy(() => import('./pages/HomeV2Page'));
// const HomeV3Page = lazy(() => import('./pages/HomeV3Page'));
const HomeV4Page = lazy(() => import('./pages/HomeV4Page'));
// const DemoV3Page = lazy(() => import('./pages/DemoV3Page'));
const PlaybackAuthPage = lazy(() => import('./pages/PlaybackAuthPage'));
const PlaybackPage = lazy(() => import('./pages/PlaybackPage'));
const PlaybackV2Page = lazy(() => import('./pages/PlaybackV2Page'));
const PlaybackV3Page = lazy(() => import('./pages/PlaybackV3Page'));
const PlaybackV4Page = lazy(() => import('./pages/PlaybackV4Page'));
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const RatingPage = lazy(() => import('./pages/RatingPage'));
const OpenPage = lazy(() => import('./pages/OpenPage'));

const App = () => {
  const logger = useLogger();
  logger.setLogLevel(LogLevel.OFF);
  const dispatch = useAppDispatch();

  const search = useLocation().search;
  const isDemo = new URLSearchParams(search).get('source');

  useEffect(() => {
    if (isDemo && isDemo.length > 0 && isDemo === isDemoRegex) {
      dispatch(setIsDemo(true));
      sessionStorage.setItem('isDemo', 'true');
      dispatch(setInternal(true));
      dispatch(setMeetingName(HunchVueDemoMeetingTitle));
    } else {
      sessionStorage.removeItem('isDemo');
    }
  }, []);

  const authState = useAppSelector((state) => state.auth);
  const meetingState = useAppSelector((state) => state.meeting);
  const playbackAuthState = useAppSelector((state) => state.playbackAuth);

  const showAuth = !authState.isAuthenticated;
  const showMeeting = authState.isAuthenticated && !meetingState.isMeetingEnded;
  const showThankYou =
    authState.isAuthenticated &&
    meetingState.isMeetingEnded &&
    !authState.isDemo &&
    !meetingState.showRating;
  const showDemoThankYou =
    authState.isAuthenticated &&
    meetingState.isMeetingEnded &&
    authState.isDemo &&
    !meetingState.showRating;
  const showRating =
    authState.isAuthenticated &&
    meetingState.isMeetingEnded &&
    meetingState.showRating;
  const showAuthPlayback = !playbackAuthState.isAuthenticated;
  const showPlayback = playbackAuthState.isAuthenticated;

  return (
    <SentryRoutes>
      <Route
        path="/try"
        element={<Suspense fallback={<>...</>}>{<OpenPage />}</Suspense>}
      />
      {showAuth && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <AuthPage />
            </Suspense>
          }
        />
      )}
      {showMeeting && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense
              fallback={
                <LoadingScreen message="We are setting up the environment for you. Please wait..." />
              }
            >
              {/* {authState.version === 'v2' && <HomeV2Page />} */}
              {/* {authState.version === 'v3' && !authState.isDemo && (
                  <HomeV3Page />
                )} */}
              {/* {authState.version === 'v3' && <HomeV3Page />} */}
              {authState.version === 'v4' && <HomeV4Page />}
              {/* {authState.version === 'v3' ||
                  (authState.version === 'v4' && false && <DemoV3Page />)} */}
            </Suspense>
          }
        />
      )}

      {showRating && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <RatingPage />
            </Suspense>
          }
        />
      )}

      {showThankYou && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <ThankYouPage />
            </Suspense>
          }
        />
      )}

      {showDemoThankYou && (
        <Route
          path="/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <DemoThankYouPage />
            </Suspense>
          }
        />
      )}

      {/* The below is for Playback which is available for only Companies and Admins */}
      {showAuthPlayback && (
        <Route
          path="playback/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              <PlaybackAuthPage />
            </Suspense>
          }
        />
      )}
      {showPlayback && (
        <Route
          path="playback/:meetingTitle/:meetingId"
          element={
            <Suspense fallback={<>...</>}>
              {playbackAuthState.version === 'v1' && <PlaybackPage />}
              {playbackAuthState.version === 'v2' && <PlaybackV2Page />}
              {playbackAuthState.version === 'v3' && <PlaybackV3Page />}
              {playbackAuthState.version === 'v4' && (
                <Sentry.ErrorBoundary
                  fallback={
                    <ErrorFallback>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '50rem',
                        }}
                      >
                        {errorBoundaryMessages.boundaryText}
                      </div>
                    </ErrorFallback>
                  }
                >
                  <PlaybackV4Page />
                </Sentry.ErrorBoundary>
              )}
            </Suspense>
          }
        />
      )}
      <Route
        path="/:meetingTitle/:meetingId/share"
        element={
          <Suspense fallback={<>...</>}>
            <HunchVueSharePage />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<>...</>}>
            <ErrorPage errorType="INVALID_LINK" />
          </Suspense>
        }
      />
    </SentryRoutes>
  );
};

export default App;
