import { createTheme } from '@mui/material/styles';
import colors from './abstracts/_variables.scss';

declare module '@mui/material/styles' {
  interface Theme {
    ui: {
      primary: string;
      primaryHover: string;
    };
  }
  interface ThemeOptions {
    ui?: {
      primary?: string;
      primaryHover?: string;
    };
  }
}

//Note: Changes to theme colors should be in sync with scss variables
const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
    htmlFontSize: 10,
  },
  palette: {
    primary: {
      main: colors.uiPrimary,
      contrastText: colors.textPrimaryDark,
    },
    secondary: {
      main: colors.textPrimaryDark,
      contrastText: colors.textPrimaryDark,
    },
    success: {
      main: colors.successContent,
      contrastText: colors.textPrimaryDark,
    },
    info: {
      main: colors.infoContent,
    },
    warning: {
      main: colors.warningContent,
      contrastText: colors.textPrimaryDark,
    },
    error: {
      main: colors.errorContent,
    },
  },
  ui: {
    primary: colors.uiPrimary,
    primaryHover: colors.uiPrimaryHover,
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.dividerLight,
            transition: 'all 300ms ease-out',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: colors.uiPrimary,
          color: colors.white,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            color: colors.textPrimaryDark,
            backgroundColor: colors.dividerLight,
          },
        },
      },
    },
  },
});

export default theme;
