import { LinearProgress } from '@mui/material';

import classes from './index.module.scss';
import HunchVueLogo from '../../assets/images/HunchVueMainLogo.svg';
import SessionNotStarted from '../../assets/images/SessionNotStarted.png';

interface LoadingScreenProps {
  message: string;
}

const LoadingScreen = ({ message }: LoadingScreenProps) => {
  return (
    <section className={classes.container}>
      <img className={classes.logo} src={HunchVueLogo} alt="HunchVue" />
      <img
        className={classes.loadingImage}
        src={SessionNotStarted}
        alt="Loading"
      />
      <div className={classes.loadingInfo}>
        <span className={classes.loadingText}>{message}</span>
        <LinearProgress variant="indeterminate" color="inherit" />
      </div>
    </section>
  );
};

export default LoadingScreen;
